import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'localTime',
})
export class LocalTimePipe implements PipeTransform {
  transform(value: string): string {
    if (value === undefined) {
      return '';
    }
    return moment(value, 'DD-MMM-YYYY HH:mm')
      .subtract(new Date().getTimezoneOffset(), 'minutes')
      .format('DD-MMM-YYYY HH:mm');
  }
}
