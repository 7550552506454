<mat-card>
  <mat-card-title-group>
    <mat-card-title>Login</mat-card-title>
  </mat-card-title-group>
  <mat-card-content>
    <form [formGroup]="form" fxLayout="column">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
        <mat-form-field fxFlex>
          <mat-label>Username</mat-label>
          <input matInput #nameElement placeholder="Username" formControlName="username" />
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
        <mat-form-field fxFlex>
          <mat-label>Password</mat-label>
          <input
            matInput
            placeholder="Password"
            formControlName="password"
            [type]="hide ? 'password' : 'text'"
            (keyup.enter)="login()"
          />
          <mat-icon matSuffix (click)="hide = !hide">{{
            hide ? 'visibility' : 'visibility_off'
          }}</mat-icon>
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button (click)="login()" color="primary">Login</button>
  </mat-card-actions>
</mat-card>
