import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ToasterService } from '../../core/toaster.service';
import { CookieService } from '../../shared/cookie.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild('nameElement', { static: true }) nameElement?: ElementRef;
  form: FormGroup;
  hide: boolean;
  private returnUrl: string;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private toaster: ToasterService,
    private cs: CookieService,
    private fb: FormBuilder,
  ) {
    this.hide = true;
    this.returnUrl = '';

    // Create form
    this.form = this.fb.group({
      username: '',
      password: '',
    });
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.nameElement !== undefined) {
        this.nameElement.nativeElement.focus();
      }
    }, 0);
  }

  login(): void {
    const formModel = this.form.value;
    const { username } = formModel;
    const { password } = formModel;
    this.auth
      .login(username, password)
      // .pipe(first())
      .subscribe(
        () => {
          this.router.navigate([this.returnUrl]);
        },
        (error) => {
          this.toaster.show('Error', error.error.details);
        },
      );
  }
}
