<mat-toolbar class="fixed-to-top">
  <a mat-button [routerLink]="['/']">
    <mat-icon>home</mat-icon>
    Home
  </a>
  <a mat-button [routerLink]="['/cause-list']">Cause List</a>
  <a mat-button [routerLink]="['/compliance-list']">Compliance List</a>
  <mat-menu #mastersMenu="matMenu">
    <a mat-menu-item routerLink="/acts">Acts</a>
    <a mat-menu-item routerLink="/advocates">Advocates</a>
    <a mat-menu-item routerLink="/case-sources">Case Sources</a>
    <a mat-menu-item routerLink="/case-types">Case Types</a>
    <a mat-menu-item routerLink="/contacts">Contacts</a>
    <a mat-menu-item routerLink="/courts">Courts</a>
    <a mat-menu-item routerLink="/court-statuses">Court Statuses</a>
    <a mat-menu-item routerLink="/departments">Departments</a>
    <a mat-menu-item routerLink="/natures">Natures</a>
    <a mat-menu-item routerLink="/offices">Offices</a>
    <a mat-menu-item routerLink="/office-statuses">Office Statuses</a>
  </mat-menu>
  <button mat-button [matMenuTriggerFor]="mastersMenu">Masters</button>

  <span class="fill-remaining-space"></span>
  <mat-menu #userMenu="matMenu">
    <a mat-menu-item (click)="logout()">Logout</a>
    <a mat-menu-item routerLink="/users/me">Change Password</a>
    <a mat-menu-item routerLink="/users">Users</a>
    <a mat-menu-item routerLink="/roles">Roles</a>
    <a mat-menu-item routerLink="/settings">Settings</a>
  </mat-menu>
  <button
    mat-button
    [matMenuTriggerFor]="userMenu"
    *ngIf="(auth.currentUser | async)?.name as name"
  >
    <mat-icon>account_box</mat-icon>
    {{ name }}
  </button>
  <a mat-button routerLink="/login" *ngIf="(auth.currentUser | async) === null">
    <mat-icon>account_box</mat-icon>
    Login</a
  >
</mat-toolbar>
<div class="toolbar-fix"></div>
