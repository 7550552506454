import { UserGroup } from './user-group';

export class User {
  id: string | undefined;
  name: string;
  password: string;
  lockedOut: boolean;
  roles: UserGroup[];
  perms: string[];
  access_token?: string;
  exp: number;
  ver: string;

  public constructor(init?: Partial<User>) {
    this.id = undefined;
    this.name = '';
    this.password = '';
    this.lockedOut = true;
    this.roles = [];
    this.perms = [];
    this.exp = 0;
    this.ver = '';
    Object.assign(this, init);
  }
}
