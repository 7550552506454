import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';

const routes: Routes = [
  {
    path: 'acts',
    loadChildren: () => import('./acts/acts.module').then((mod) => mod.ActsModule),
  },
  {
    path: 'advocates',
    loadChildren: () => import('./advocates/advocates.module').then((mod) => mod.AdvocatesModule),
  },
  {
    path: 'cause-list',
    loadChildren: () => import('./cause-list/cause-list.module').then((mod) => mod.CauseListModule),
  },
  {
    path: 'case-sources',
    loadChildren: () =>
      import('./case-sources/case-sources.module').then((mod) => mod.CaseSourcesModule),
  },
  {
    path: 'case-types',
    loadChildren: () => import('./case-types/case-types.module').then((mod) => mod.CaseTypesModule),
  },
  {
    path: 'cases',
    loadChildren: () => import('./cases/cases.module').then((mod) => mod.CasesModule),
  },
  {
    path: 'compliance-list',
    loadChildren: () =>
      import('./compliance-list/compliance-list.module').then((mod) => mod.ComplianceListModule),
  },
  {
    path: 'contacts',
    loadChildren: () => import('./contacts/contacts.module').then((mod) => mod.ContactsModule),
  },
  {
    path: 'courts',
    loadChildren: () => import('./courts/courts.module').then((mod) => mod.CourtsModule),
  },
  {
    path: 'court-statuses',
    loadChildren: () =>
      import('./court-statuses/court-statuses.module').then((mod) => mod.CourtStatusesModule),
  },
  {
    path: 'departments',
    loadChildren: () =>
      import('./departments/departments.module').then((mod) => mod.DepartmentsModule),
  },
  {
    path: 'natures',
    loadChildren: () => import('./natures/natures.module').then((mod) => mod.NaturesModule),
  },
  {
    path: 'offices',
    loadChildren: () => import('./offices/offices.module').then((mod) => mod.OfficesModule),
  },
  {
    path: 'office-statuses',
    loadChildren: () =>
      import('./office-statuses/office-statuses.module').then((mod) => mod.OfficeStatusesModule),
  },
  {
    path: 'roles',
    loadChildren: () => import('./roles/roles.module').then((mod) => mod.RolesModule),
  },
  {
    path: 'tax-report',
    loadChildren: () => import('./tax-report/tax-report.module').then((mod) => mod.TaxReportModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then((mod) => mod.UsersModule),
  },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: '', redirectTo: '/cases', pathMatch: 'full' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
