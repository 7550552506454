import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { AccountingPipe } from './accounting.pipe';
import { ClearPipe } from './clear.pipe';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { LocalTimePipe } from './local-time.pipe';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  declarations: [
    ConfirmDialogComponent,
    ImageDialogComponent,
    AccountingPipe,
    ClearPipe,
    LocalTimePipe,
  ],
  exports: [AccountingPipe, ClearPipe, LocalTimePipe],
})
export class SharedModule {}
