import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../auth/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // add authorization header with jwt token if available

    // We use this line to debug token refreshing
    if (!this.isRefreshing && this.authService.user && this.authService.needsRefreshing()) {
      this.isRefreshing = true;
      this.authService.refreshToken().subscribe(() => {
        this.isRefreshing = false;
      });
    }
    const currentUser = this.authService.user;
    if (currentUser?.access_token) {
      // eslint-disable-next-line no-param-reassign
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.access_token}`,
        },
      });
    }

    return next.handle(request);
  }
}
