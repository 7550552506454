import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';

import { ToasterService } from './toaster.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private toaster: ToasterService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        // We don't want to refresh token for some requests like login or refresh token itself
        // So we verify url and we throw an error if it's the case
        if (request.url.includes('/refresh') || request.url.includes('/token')) {
          // We do another check to see if refresh token failed
          // In this case we want to logout user and to redirect it to login page
          if (request.url.includes('/refresh')) {
            this.authService.logout();
          }
          return throwError(err);
        }
        // If error status is different than 401 we want to skip refresh token
        // So we check that and throw the error if it's the case
        if (err.status !== 401) {
          const error = err.error.message || err.error.detail || err.statusText;
          return throwError(error);
        }
        // auto logout if 401 response returned from api
        this.authService.logout();
        this.toaster.show('Error', 'User has been logged out');
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '250px',
          data: {
            title: 'Logged out!',
            content:
              'You have been logged out.\n' +
              'You can press Cancel to stay on page and login in another tab to resume here,' +
              ' or you can press Ok to navigate to the login page.',
          },
        });
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.router.navigate(['login']);
          }
        });
        return throwError(err);
      }),
    );
  }
}
