import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../core/user';
import { CookieService } from '../shared/cookie.service';

const loginUrl = '/token';
const refreshUrl = '/refresh';
const JWT_USER = 'JWT_USER';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public currentUser: Observable<User | null>;
  private currentUserSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  constructor(private http: HttpClient, private cs: CookieService) {
    this.checkStorage();
    this.currentUser = this.currentUserSubject.asObservable();
  }

  static parseJwt(token: string): User {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );

    const decoded = JSON.parse(jsonPayload);
    return new User({
      id: decoded.userId,
      name: decoded.sub,
      lockedOut: decoded.lockedOut,
      perms: decoded.scopes,
      access_token: token,
      exp: decoded.exp,
      ver: decoded.ver,
    });
  }

  checkStorage(): User | null {
    const storageToken = localStorage.getItem(JWT_USER);
    let existingToken: User | null = null;
    if (storageToken !== null) {
      existingToken = JSON.parse(storageToken);
    }
    if (existingToken === null || Date.now() > existingToken.exp * 1000) {
      localStorage.removeItem(JWT_USER);
      this.currentUserSubject.next(null);
      return null;
    }
    this.currentUserSubject.next(existingToken);
    return existingToken;
  }

  public get user(): User | null {
    let val = this.currentUserSubject.value;
    if (val == null) {
      return val;
    }
    let expired = Date.now() > val.exp * 1000;
    if (expired) {
      val = this.checkStorage();
    }
    if (val == null) {
      return new User();
    }
    expired = Date.now() > val.exp * 1000;
    if (expired) {
      this.logout();
    }
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    const formData: FormData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('grant_type', 'password');
    return this.http.post<{ access_token: string; token_type: string }>(loginUrl, formData).pipe(
      map((u) => u.access_token),
      map((u: string) => AuthService.parseJwt(u)),
      map((user: User) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem(JWT_USER, JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }),
    );
  }

  needsRefreshing(): boolean {
    const { user } = this;
    if (user === null) {
      return true;
    }
    return Date.now() > (user.exp - environment.ACCESS_TOKEN_REFRESH_MINUTES * 60) * 1000;
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(JWT_USER);
    this.currentUserSubject.next(null);
  }

  refreshToken() {
    return this.http.post<{ access_token: string; token_type: string }>(refreshUrl, {}).pipe(
      map((u) => u.access_token),
      map((u: string) => AuthService.parseJwt(u)),
      map((user: User) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem(JWT_USER, JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }),
    );
  }

  allowed(permission: string): boolean {
    const { user } = this;
    if (user == null || user.perms.indexOf(permission) === -1) {
      return false;
    }
    return true;
  }
}
