import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clear',
})
export class ClearPipe implements PipeTransform {
  transform(value: string | null): string {
    if (value === null) {
      return '';
    }
    return value === '₹ 0.00' || value === '0.00' ? '' : value;
  }
}
