import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accounting',
})
export class AccountingPipe implements PipeTransform {
  transform(value: string): string {
    if (value === null) {
      return '';
    }
    const amount = +value.replace(new RegExp('(₹[s]*)|(,)', 'g'), '');
    return value.replace('-', '') + (amount < 0 ? '\u00A0Cr' : '\u00A0Dr');
  }
}
